/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');
//require('../../public/css/style.css');
require('../../public/css/bulma.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');

var cbpHorizontalMenu = (function () {
	var $listItems = $('#cbp-hrmenu > ul > li');
	var $menuItems = $listItems.children('a');
	//var	$directLink = $('.direct-link');
	//var	$directLinkHref = $('.direct-link').attr('href');
	var $body = $('body');
	var current = -1;

	function init() {
		$menuItems.on('click', open);
		//$directLink.on('click', goToLink);
		$listItems.on('click', function (event) {
			jQuery('#cbp-hrmenu').close();
			event.stopPropagation();
		});
	}

	function goToLink() {
		window.location.href = $directLinkHref;
	}

	function open(event) {
		if (!$(this).hasClass('direct-link')) {
			if (current !== -1) {
				$listItems.eq(current).removeClass('cbp-hropen');
			}
			var $item = $(event.currentTarget).parent('li'),
				idx = $item.index();
			if (current === idx) {
				$item.removeClass('cbp-hropen');
				current = -1;
			} else {
				$item.addClass('cbp-hropen');
				current = idx;
				$body.off('click').on('click', close);
			}
			return false;
		}
	}

	function close(event) {
		$listItems.eq(current).removeClass('cbp-hropen');
		current = -1;
	}
	return {
		init: init
	};
})();

// On Document Ready!
$(function () {
	$('#switchRoundedInfo').attr('checked', 'true');

	// $('#switchRoundedInfo').click(function(){ 
	//    	if($("#switchRoundedInfo").is(':checked')){
	//    		alert('checked');
	//    		$('#switchRoundedInfo').attr('checked','false');
	//    		$('.priceMensual').css('display','none!important');
	//    		$('.priceAnual').css('display','block!important');
	// 	}
	// 	else{
	// 		$('#switchRoundedInfo').attr('checked','true');
	//    		$('.priceMensual').css('display','block!important');
	//    		$('.priceAnual').css('display','none!important');
	// 	};
	//    });

	$('#switchRoundedInfo').change(function () {
		$('#switchRoundedInfo').attr('checked', function (index, attr) {
			return attr == 'checked' ? null : '';
		});
		$('.priceMensual').toggle();
		$('.priceAnual').toggle();
		// ($('#switchRoundedInfo').toggleeAttr( "checked" ));
		// $('#switchRoundedInfo').attr('checked','false');
		// $('.priceMensual').css('display','none!important');
		// $('.priceAnual').css('display','block!important');
	});


	// Acciones del Menu
	cbpHorizontalMenu.init();
	$('.menu-toggle').click(function () {
		$('.cbp-hrmenu > ul').toggleClass('open');
		$('.topbar').toggleClass('active');
	});

	// Live Agent y botón de chat
	var chatButton;
	(function (d, src, c) { var t = d.scripts[d.scripts.length - 1], s = d.createElement('script'); s.id = 'la_x2s6df8d'; s.async = true; s.src = src; s.onload = s.onreadystatechange = function () { var rs = this.readyState; if (rs && (rs != 'complete') && (rs != 'loaded')) { return; } c(this); }; t.parentElement.insertBefore(s, t.nextSibling); })(document,
		'//la.latincloud.com/scripts/track.js',
		function (e) { chatButton = LiveAgent.createButton('ef7a756f', e); });
	$(".form-trigger,.chat-trigger").on('click touchstart', function (e) {
		// e.preventDefault();
		chatButton.onClick();
	});

	// Dropdown de paises
	$('#select-pais').click(function () {
		$('.dropdown-paises').toggleClass('open');
	});

	$('.close-btn-chat').click(function (){
		$('#chat-message-view').hide();
	})

	/* grecaptcha.ready(function () {
		grecaptcha.execute('6LdUBKkZAAAAAO-KFi9aUGK_rXOaQOy0n73h90W6', { action: 'validate_captcha' }).then(function (token) {
			$('.g-recaptcha-response').each(function () {
				$(this).val(token);
			});
		});
	});
	// Defino el Recaptcha
	setInterval(function () {
		grecaptcha.ready(function () {
			grecaptcha.execute('6LdUBKkZAAAAAO-KFi9aUGK_rXOaQOy0n73h90W6', { action: 'validate_captcha' }).then(function (token) {
				$('.g-recaptcha-response').each(function () {
					$(this).val(token);
				});
			});
		});
	}, 20000); */

	/* // Submit de form de Growhit NO SE ESTA USANDO
	$('.growhit-submit').click(function (e) {
		e.preventDefault();
		if ($('.growhit-form')[0].checkValidity()) {
			$.ajax({
				url: '/recaptcha',
				type: 'post',
				data: $('.growhit-form').serialize(),
				dataType: 'json',
				success: function (response) {
					if (response.status == 'ok') {
						$('.growhit-form')[0].submit();
					}
					else {
						alert("Hubo un error validando su identidad, por favor recargue el sitio para enviar el formulario.");
					}
				}
			});
		}
		else {
			$('.growhit-submit')[0].reportValidity();
		}
	});
 */
	// Modal para los tooltips
	$('.tooltips').click(function (e) {
		var contenido = $(this).data('modal');
		var modal = $('.modal');
		var titulo = $(this).parent().find('p').html();
		modal.find(".modal-card-title").html(titulo);
		modal.find(".modal-card-body").html(contenido);
		modal.addClass("is-active");
	});
	$('.close-modal').click(function (e) {
		var modal = $('.modal');
		modal.removeClass("is-active");
	});
	$(document).mouseup(function (e) {
		var container = $(".modal-card");
		if (!container.is(e.target) && container.has(e.target).length === 0) {
			var modal = $('.modal');
			modal.removeClass("is-active");
		}
	});

	$('.ver-mas').click(function () {
		$('.expandedFeatures').show(300);
		$('.ver-mas').hide();
		$('.ver-menos').show();
	});
	$('.ver-menos').click(function () {
		$('.expandedFeatures').hide(300);
		$('.ver-menos').hide();
		$('.ver-mas').show();
	});

	// Si esta el selector de ciclos le doy funcionalidad
	if ($('.checkCycle').length) {
		$('.checkCycle').change(function () {
			var cycle = $(this).val();
			if (cycle == 'biennially') {
				$('.priceMonthly').hide();
				$('.priceAnnually').hide();
				$('.priceBiennially').show();
			}
			else if (cycle == 'monthly') {
				$('.priceMonthly').show();
				$('.priceAnnually').hide();
				$('.priceBiennially').hide();
			}
			else {
				$('.priceMonthly').hide();
				$('.priceAnnually').show();
				$('.priceBiennially').hide();
			}
		});
	}
});